import styles from '../styles/Home.module.css';
import MyHeader from '../components/myheader';
import Footer from '../components/footer';
const Layout : React.VFC<{pageTitle: string, children: JSX.Element}> = ({pageTitle, children}) => {
    return (
        <div className={styles.container}>
            <MyHeader pageTitle={pageTitle} />
            {children}
            <Footer />
        </div>
    );
};
export default Layout;
