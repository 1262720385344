import { Wrap, WrapItem, Link, Icon } from "@chakra-ui/react";
import { FiTwitter, FiInstagram, FiFacebook } from "../node_modules/react-icons/fi";
const Footer = () => {
    return (
        <Wrap spacing={4} mt="36px">
            <WrapItem>
                <Link href="https://twitter.com/tkusano" isExternal>
                    <Icon as={FiTwitter} />
                </Link>
            </WrapItem>
            <WrapItem>
                <Link href="https://www.instagram.com/tkusano/" isExternal>
                    <Icon as={FiInstagram} />
                </Link>
            </WrapItem>
            <WrapItem>
                <Link href="https://www.facebook.com/tkusano" isExternal>
                    <Icon as={FiFacebook} />
                </Link>
            </WrapItem>
        </Wrap>
    );
};
export default Footer;
